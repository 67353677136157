import React, { Suspense, useMemo } from 'react';
import { RefreshControl, SectionList, View } from 'react-native';
import { GuideElement, STEPS, useTourGuide } from '../tour';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { Margin } from 'src/constants';
import { Headline, HoverButton, Row } from 'src/components';
import SuspenseRequestItem from './SuspenseRequestItem';
import RequestItem from './RequestItem';
import { useTranslation } from 'react-i18next';
import { useViewMode } from 'src/hooks';
import {
  EmptyListCard,
  NoRequestText,
  SectionHeaderContainer,
  SectionTitle,
  StyledHeroIcon
} from '../styles';
import { Inbox } from 'react-native-feather';
import { RequestFilterCriteria } from '../helper/requestFilter';
import usePaginatedPatients from 'src/hooks/react-query/usePaginatedPatients';
import { AppointmentRequest, RequestStatus, RequestType } from 'src/interfaces';
import moment from 'moment';

interface Props {
  data: any;
  isFetched: boolean;
  isRefetching: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFilters: React.Dispatch<React.SetStateAction<RequestFilterCriteria>>;
  filterCount: number;
}

const RequestList: React.FC<Props> = ({
  data,
  isFetched,
  isRefetching,
  refetch,
  setOpen,
  setFilters,
  filterCount
}) => {
  const { t } = useTranslation('requests');
  const { colors } = useAppTheme();
  const { horizontalInset } = useViewMode();

  const { enabled: isTouring } = useTourGuide();

  const { data: patients } = usePaginatedPatients({});

  const tourDummyData = useMemo(() => {
    const request: AppointmentRequest = {
      type: RequestType.Appointment,
      patient: patients[0],
      patientId: patients[0].patientId,
      content: {
        preferredDate: moment().add(1, 'day').utc().toISOString(),
        preferredTime: '12:00pm'
      }
    };
    return [
      {
        title: 'sectionTitles.newAndInProgress',
        tourBody: 'tour.newAndInProgress',
        step: STEPS.NEW_AND_IN_PROGRESS,
        data: [{ ...request, status: RequestStatus.New }]
      },
      {
        title: 'sectionTitles.archived',
        tourBody: 'tour.archived',
        step: STEPS.ARCHIVED,
        data: [{ ...request, status: RequestStatus.Archived }]
      }
    ];
  }, [patients]);

  return (
    <SectionList
      refreshControl={
        <RefreshControl refreshing={isRefetching} onRefresh={refetch} tintColor={colors.primary} />
      }
      style={{ flex: 1 }}
      contentContainerStyle={{
        gap: Margin.Medium,
        padding: Margin.Medium,
        marginHorizontal: horizontalInset
      }}
      sections={isTouring ? tourDummyData : data}
      stickySectionHeadersEnabled={false}
      renderSectionHeader={({ section: { index, title, step, tourBody, data } }) => (
        <GuideElement
          id={step ?? 0}
          body={t(tourBody ?? '')}
          autoStart={isFetched}
          disabled={!step}
          semiTransparentBg
          sectionIndex={index}
        >
          <SectionHeaderContainer>
            <Row justify='space-between'>
              <SectionTitle>{t(title)}</SectionTitle>
              {!!filterCount && (
                <HoverButton uppercase={false} onPress={() => setFilters({})} compact>
                  {t('common:clearFilters')}
                </HoverButton>
              )}
            </Row>
            {!!filterCount && !data.length && <NoRequestText>{t('noResults')}</NoRequestText>}
          </SectionHeaderContainer>
        </GuideElement>
      )}
      renderItem={({ item }) => (
        <Suspense fallback={<SuspenseRequestItem />}>
          <RequestItem request={item} />
        </Suspense>
      )}
      ListEmptyComponent={() => (
        <EmptyListCard>
          <StyledHeroIcon icon={Inbox} color={colors.primary} />
          <NoRequestText as={Headline}>{t('noRequests.title')}</NoRequestText>
          <NoRequestText>{t('noRequests.description')}</NoRequestText>
          <View>
            <HoverButton mode='contained' onPress={() => setOpen(true)}>
              {t('noRequests.cta')}
            </HoverButton>
          </View>
        </EmptyListCard>
      )}
    />
  );
};

export default RequestList;
