import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image, View } from 'react-native';
import { Screens } from 'src/routes/stacks/screens';
import ActionButtons from './ActionButtons';
import styled from 'styled-components/native';
import {
  IMG_ASPECT_RATIO_MOBILE,
  IMG_ASPECT_RATIO_STANDARD,
  IS_WEB,
  Margin,
  Size
} from 'src/constants';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import RenderHTML from 'react-native-render-html';
import HomeStackParamsList from 'src/routes/stacks/HomeStackNavigator/ParamsList';
import Toast from 'react-native-toast-message';
import { useViewMode } from 'src/hooks';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment-timezone';
import { useMutation } from 'react-query';
import { postLifelearnFeedback } from 'src/api/lifelearn';
import { MutationKeys } from 'src/api';
import useLifelearnArticles from 'src/hooks/react-query/useLifelearnArticles';
import * as Print from 'expo-print';
import { AxiosError } from 'axios';
import { StyledFAB } from './shared';
import { ChevronLeft } from 'react-native-feather';
import useGoBack from 'src/hooks/useGoBack';
import { fontStyles } from 'src/theme/globalStyles';
import { useReactToPrint } from 'react-to-print';
import { useNavigation } from '@react-navigation/native';

type Props = NativeStackScreenProps<HomeStackParamsList, Screens.ARTICLE>;

const Article: React.FC<Props> = ({ route }) => {
  const { setOptions } = useNavigation();
  const { t } = useTranslation('articleHub');
  const { isMobile } = useViewMode();
  const { colors } = useAppTheme();
  const goBack = useGoBack();

  const articleId =
    typeof route.params.id === 'string' ? parseInt(route.params.id) : route.params.id;
  const { article } = useLifelearnArticles({ articleId });

  useEffect(() => {
    setOptions({
      headerLeft: () => (
        <StyledFAB
          icon={({ color, size }) => <ChevronLeft color={color} width={size} height={size} />}
          style={{ marginLeft: Margin.Large }}
          onPress={() => goBack()}
        />
      ),
      title: article?.title
    });
  }, [goBack, article?.title, setOptions]);

  const [ratingButtonsDisabled, setRatingButtonsDisabled] = useState<boolean>(false);

  const [start, setStart] = useState<Moment>(moment());

  useEffect(() => {
    setStart(moment());
  }, []);

  const { mutateAsync: rateArticle } = useMutation({
    mutationKey: [MutationKeys.EDITING_TODOS],
    mutationFn: postLifelearnFeedback,
    onSuccess: () => {
      setRatingButtonsDisabled(true);
      Toast.show({ text1: t('ratingPosted') });
    },
    onError: (error: Error) => {
      Toast.show({
        type: 'error',
        text1: error.name,
        text2: error.message
      });
      throw error;
    }
  });

  const htmlRef = useRef<View>(null);
  const handleWebPrint = useReactToPrint({
    content: () => htmlRef.current
  });
  const handleNativePrint = useCallback(async () => {
    if (article) {
      await Print.printAsync({ html: article.content });
    }
  }, [article]);

  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = useCallback(async () => {
    if (IS_WEB) {
      setIsPrinting(true);
      setTimeout(() => {
        handleWebPrint();
        setIsPrinting(false);
      }, 0);
    } else {
      await handleNativePrint();
    }
  }, [handleNativePrint, handleWebPrint]);

  const onPressRate = useCallback(
    async (isThumbsUp: boolean) => {
      if (article) {
        const timeSpentInSec = Math.round(moment().diff(start) / 1000);
        try {
          await rateArticle({
            articleId: article.id,
            rating: +isThumbsUp,
            timeSpentInSec,
            feedback: ''
          });
        } catch (e) {
          throw e as AxiosError;
        }
      }
    },
    [article, start, rateArticle]
  );

  if (!article) return null;

  return (
    <>
      <Container>
        <Image
          source={{ uri: article.featured_image.image }}
          style={{
            aspectRatio: isMobile ? IMG_ASPECT_RATIO_MOBILE : IMG_ASPECT_RATIO_STANDARD
          }}
        />
        <LowerContainer>
          <View
            ref={htmlRef}
            style={[
              isPrinting && {
                width: '100%'
              }
            ]}
          >
            <RenderHTML
              source={{ html: article.content }}
              baseStyle={
                isPrinting
                  ? undefined
                  : {
                      color: colors.text,
                      padding: Margin.ExtraLarge
                    }
              }
              enableExperimentalMarginCollapsing
              enableCSSInlineProcessing
              tagsStyles={fontStyles}
              ignoredStyles={['fontSize', 'lineHeight']}
            />
          </View>
          <ActionButtonsContainer>
            <ActionButtons
              print={handlePrint}
              rate={onPressRate}
              ratingButtonsDisabled={ratingButtonsDisabled}
            />
          </ActionButtonsContainer>
        </LowerContainer>
      </Container>
    </>
  );
};

export default Article;

const Container = styled.ScrollView.attrs(({ theme }) => ({
  contentContainerStyle: { marginHorizontal: theme.viewMode.horizontalInset }
}))``;

const LowerContainer = styled.View`
  gap: ${Size.S}px;
`;

const ActionButtonsContainer = styled.View`
  position: absolute;
  top: ${Margin.Medium}px;
  right: ${Margin.Medium}px;
`;
