import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import RenderHTML, { MixedStyleDeclaration } from 'react-native-render-html';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import { fonts } from 'src/theme';
import { fontStyles } from 'src/theme/globalStyles';

interface Props {
  fallback: React.ReactNode;
  children?: string | null;
  baseStyle?: MixedStyleDeclaration | undefined;
}

/**
 * Component to formatted practice provided text
 * @param children - text to render
 * @param fallback - fallback component to render if no text is provided, must be a react node
 *
 * @remark This should never be a child of Text component, as it will not render correctly
 */
const RichTextRenderer: React.FC<Props> = ({ children, fallback, baseStyle }) => {
  const { colors } = useAppTheme();
  const style = useMemo(
    () =>
      StyleSheet.flatten([
        { color: colors.onSurface, fontFamily: fonts.roboto.regular },
        baseStyle
      ]),
    [baseStyle, colors.onSurface]
  );

  if (!children?.length) {
    if (fallback) {
      return <>{fallback}</>;
    } else return null;
  }
  return (
    <RenderHTML
      enableExperimentalMarginCollapsing
      enableCSSInlineProcessing
      baseStyle={style}
      source={{ html: children }}
      tagsStyles={fontStyles}
      ignoredStyles={['fontSize']}
    />
  );
};

export default RichTextRenderer;
