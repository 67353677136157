import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Image } from 'react-native';
import { useAppTheme } from 'src/providers/AppThemeProvider';
import HomeStackParamsList from 'src/routes/stacks/HomeStackNavigator/ParamsList';
import { Screens } from 'src/routes/stacks/screens';
import styled from 'styled-components/native';
import { Trans, useTranslation } from 'react-i18next';
import { IconSize, IMG_ASPECT_RATIO_MOBILE, IMG_ASPECT_RATIO_STANDARD, Size } from 'src/constants';
import { cleanUrl } from 'src/utils';
import AnimalIcon from 'src/components/Icons/Animal';
import { Body, Subheading } from 'src/components/Text';
import { Weight } from 'src/theme';
import { SlideItem, SlideProps } from 'src/components/kit/Slider/Slide';

export interface LifelearnSlideItem extends SlideItem {
  id: number;
  imageUri: string;
}

const LifelearnSlide: React.FC<SlideProps<LifelearnSlideItem>> = ({ item, width }) => {
  const { t } = useTranslation('home');
  const { navigate } = useNavigation<NavigationProp<HomeStackParamsList>>();
  const { colors, viewMode } = useAppTheme();

  const [imageError, setImageError] = useState(false);

  const openArticle = () => {
    if (item.id) {
      navigate(Screens.ARTICLE, { id: item.id });
    }
  };

  const aspectRatio = viewMode.isMobile ? IMG_ASPECT_RATIO_MOBILE : IMG_ASPECT_RATIO_STANDARD;

  return (
    <Container width={width}>
      {!imageError && (
        <Image
          source={{ uri: cleanUrl(item.imageUri) }}
          style={{ aspectRatio }}
          onError={() => setImageError(true)}
        />
      )}
      {imageError && (
        <AnimalIconContainer aspectRatio={aspectRatio}>
          <AnimalIcon size={IconSize.XXXL} color={colors.disabled} />
        </AnimalIconContainer>
      )}
      <InnerContainer>
        <Subheading>{item.title}</Subheading>
        <Body>
          <Trans
            i18nKey={t('lifelearnSummary')}
            values={{ summary: item.body }}
            components={{
              strongLink: (
                <Body
                  style={{ textDecorationLine: 'underline' }}
                  fontWeight={Weight.BOLD}
                  onPress={openArticle}
                />
              )
            }}
          />
        </Body>
      </InnerContainer>
    </Container>
  );
};

export default LifelearnSlide;

const Container = styled.View<{ width?: number }>`
  ${({ width }) => (width ? `width: ${width}px;` : '')}
`;

const InnerContainer = styled.View`
  gap: ${Size.S}px;
  padding-top: ${Size.S}px;
  padding-horizontal: ${Size.S}px;
`;

const AnimalIconContainer = styled.View<{ aspectRatio: number }>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  align-items: center;
  justify-content: center;
`;
