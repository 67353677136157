import moment from 'moment';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from 'src/api';
import { onDemandSync, onDemandStatus, OnDemandStatus } from 'src/api/onDemandSync';
import { ApiPracticeInfo } from 'src/interfaces';

const VALID_DURATION = moment.duration(5, 'm').asMilliseconds();
const POLL_INTERVAL = moment.duration(5, 's').asMilliseconds();
const TIMEOUT = moment.duration(90, 's').asMilliseconds();

const useOnDemandPoll = (practiceInfo: ApiPracticeInfo) => {
  const {
    data,
    isFetched,
    isError: syncError
  } = useQuery({
    queryKey: [QueryKeys.ON_DEMAND_SYNC, practiceInfo.practiceId],
    queryFn: async () => onDemandSync({ practiceId: practiceInfo.practiceId }),
    enabled: !!practiceInfo,
    staleTime: VALID_DURATION,
    cacheTime: VALID_DURATION
  });

  const timeoutTime = useMemo(() => {
    if (!isFetched) {
      return undefined;
    }
    return moment().add(TIMEOUT, 'ms').valueOf();
  }, [isFetched]);

  const timedOut = moment().isAfter(timeoutTime);

  const {
    data: { status } = {},
    isError: statusError,
    isFetched: statusFetched
  } = useQuery({
    queryKey: [QueryKeys.ON_DEMAND_STATUS, data?.commandIds[0]],
    queryFn: async () => onDemandStatus({ commandId: data?.commandIds[0] }),
    enabled: !!data?.commandIds[0],
    refetchInterval: (data) =>
      data?.status === OnDemandStatus.RUNNING && !timedOut ? POLL_INTERVAL : false,
    staleTime: Infinity,
    cacheTime: Infinity
  });

  return {
    lastSyncId: data?.commandIds[0],
    onDemandReady:
      statusError || syncError || timedOut || (statusFetched && status !== OnDemandStatus.RUNNING)
  };
};

export default useOnDemandPoll;
