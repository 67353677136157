import React from 'react';
import { Body } from 'src/components';
import { SlideItem, SlideProps } from 'src/components/kit/Slider/Slide';
import { Size } from 'src/constants';
import styled from 'styled-components/native';

const AnnouncementSlide: React.FC<SlideProps<SlideItem>> = ({ width, item }) => {
  if (!width) return null;
  return <CarouselItemView width={width}>{!!width && <Body>{item.body}</Body>}</CarouselItemView>;
};

export default AnnouncementSlide;

const CarouselItemView = styled.View<{ width: number }>`
  width: ${({ width }) => width}px;
  padding-horizontal: ${Size.S}px;
  margin-bottom: ${Size.S}px;
`;
